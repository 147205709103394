.wp-block-gco-map {
  height: auto;
  grid-gap: 0px;
}
.wp-block-gco-map h3, .wp-block-gco-map h4 {
  margin: 0;
}
.wp-block-gco-map aside {
  display: flex;
  flex-direction: column;
  min-height: min(80vh, 500px);
  margin-bottom: 50px;
}
.wp-block-gco-map aside.one-branch {
  height: auto;
}
.wp-block-gco-map aside ul {
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
  scroll-behavior: smooth;
}
.wp-block-gco-map aside.all-branches ul {
  height: 100%;
}
.wp-block-gco-map aside.one-branch ul {
  flex: 1 0 auto;
}
.wp-block-gco-map summary {
  cursor: pointer;
  position: relative;
  width: 100%;
  line-height: 24px;
  list-style-type: none;
}
.wp-block-gco-map summary::-webkit-details-marker, .wp-block-gco-map summary::marker {
  display: none;
}
.wp-block-gco-map summary i {
  position: absolute;
  right: 0;
  top: .13rem;
  transform: rotate(180deg);
}
.wp-block-gco-map details[open] summary i {
  transform: rotate(0deg);
}
.wp-block-gco-map aside ul li {
  padding: 0;
  margin: 0;
  padding-top: 30px;
  padding-bottom: 30px;
}
.wp-block-gco-map aside address {
  font-style: normal;
  padding-top: 20px;
  font-size: 1rem;
}
.wp-block-gco-map aside address a, .wp-block-gco-map aside address p {
  display: table;
  text-decoration: none !important;
  position: relative;
  padding-left: 30px;
  margin-bottom: .6rem;
  margin-top: 0;
}

.wp-block-gco-map aside address i {
  position: absolute;
  left: 0;
  top: 0.1rem;
}
.leaflet-control a {
  text-decoration: none !important;
}
.leaflet-container {
  height: 500px;
  position: relative;
  z-index: 0 !important;
}
.leaflet-marker-icon:focus {
  box-shadow: none !important;
}
.block-editor-inner-blocks .cta-standard {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
  font-size: .8rem !important;
}
.block-editor-inner-blocks .cta-container * {
  margin: .33rem !important;
}
.block-editor-inner-blocks .wp-block-gco-map .cta-catchphrase {
  display: none;
}
@media (min-width: 1024px) {
  .wp-block-gco-map {
    min-height: min(80vh, 500px);
  }

  .wp-block-gco-map aside {
    margin-bottom: 0;
  }

  .leaflet-container {
    height: 100%;
  }
}
